<template>
  <div>
    <Form :model="addChangeData" :label-colon="true" :inline="true">
      <FormItem style="width: 310px; margin-bottom: 0px">
         <span slot="label" class="validate">服务月份:</span>
        <DatePicker
          type="month"
          v-model="addChangeData.yearofmonth"
          placeholder="请选择服务年份"
          style="calc(100% - 50px)"
        ></DatePicker>
      </FormItem>
      <FormItem style="width: 25%; margin-bottom: 0px">
        <Checkbox v-model="addChangeData.repeatMonth">每月重复</Checkbox>
      </FormItem>
    </Form>
    <Divider style="margin: 10px 0" />
    <LiefengTable
      :talbeColumns="addChangeData.tableColumns"
      :tableData="addChangeData.tableData"
      :hidePage="true"
       :fixTable="true"
      class="planTable"
    ></LiefengTable>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
  props: ["optionsList","planTableList"],
  data() {
    return {
      addChangeData: {
        tableColumns: [
          {
            title: "计划名称",
            key: "planName",
            fixed: "left",  
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              return h("Input", {
                props: {
                  value: params.row.planName,
                },
                on: {
                  "on-change": (e) => {
                    this.addChangeData.tableData[params.index].planName =
                      e.target.value;
                  },
                },
              });
            },
          },
          {
            title: "服务内容",
            align: "center",
            children: this.optionsList ? this.optionsList.map((item,index) => {
              return {
                title: item.name,
                 align: "center",
                 width: 200,
                 render: (h, params) => {
                  return h(
                    "Select",
                    {
                      props: {
                        value: this.addChangeData.tableData[params.index].servicePlanProjectVos[index].frequency,
                        transfer: true,
                        disabled: true
                      },
                      style: {
                        textAlign: "left",
                      },
                      on: {
                        "on-change": (val) => {
                          console.log(val);
                          this.addChangeData.tableData[params.index].servicePlanProjectVos[index].frequency = val;
                        },
                      },
                    },
                    [
                      this.frequencyList.map((item) => {
                        return h(
                          "Option",
                          {
                            props: {
                              value: item.value,
                            },
                          },
                          item.label
                        );
                      }),
                    ]
                  );
                },
              }
            }) : [],
          },
          {
            title: "目标人员",
            fixed: "right",
            width: 200,
            align: "center",
            render: (h, params) => {
                let result = "";
                if(params.row.archives) {
                    params.row.archives.map((item,index,arr) => {
                        if(index == arr.length -1) {
                            result += item.name;
                        }else {
                            result += item.name + '、';
                        }
                })
                }
              return h('div',result)
            },
          },
        ],
        tableData: [],
        //目标人员
        targetList: [],  //回显的目标人员
        infoIndex: '',
        archiveIdArr: [],    //保存的每个计划的目标人员
      },
      frequencyList: [
        //服务频率
        {
          value: 0,
          label: "无",
        },
        {
          value: 1,
          label: "每周一次",
        },
        {
          value: 2,
          label: "每周两次",
        },
        {
          value: 3,
          label: "每周三次",
        },
        {
          value: 4,
          label: "每周四次",
        },
      ],
      //目标人员
      targetStatus: false,
      oldYearofmonth: '',  //修改前的所属月份
    };
  },
  methods: {
    
  },
  created() {
    if(this.planTableList && this.planTableList.length > 0) { 
      this.addChangeData.tableData = this.planTableList.map(item => {
        return {
          ...item,
          archiveIdArr: item.archives
        }
      })
      this.addChangeData.yearofmonth = this.planTableList[0].yearofmonth;
      this.oldYearofmonth = this.addChangeData.yearofmonth;
      this.addChangeData.repeatMonth = this.planTableList[0].repeatMonth == '1' ? true : false;
    }
  },
  components: {
    LiefengTable,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.planTable {
  /deep/.ivu-table-body,/deep/.ivu-table-tip {
    height: calc(100% - 80px);
  }
  /deep/.ivu-select-disabled .ivu-select-selection {
    background: #fff;
    color: #5a516e;
  }
}
</style>